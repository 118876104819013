export default function validationErrors() {
  return {
    unauthenticated: 'Вы не авторизованы',
    some_error: 'Произошла ошибка',
    big_file: 'Размер файла не должен превышать 3MB',
    network_error: 'Ошибка соединения',
    auth_wrong_data: 'Неверно указан логин или пароль',
    login_required: 'Необходимо указать логин',
    password_required: 'Необходимо указать пароль',
    reg_inn_required: 'Необходимо указать УНП',
    reg_full_name_required: 'Необходимо указать наименование юр. лица',
    reg_contact_fio_required: 'Необходимо указать контактное лицо',
    email_required: 'Необходимо указать email',
    reg_phones_required: 'Необходимо указать номер телефона',
    field_text_required: 'Необходимо указать текст сообщения',
    field_theme_required: 'Необходимо указать тему сообщения',
    no_such_user: 'Пользователь не найден',
    email_valid: 'Укажите валидный email',
    data_corrent: 'Необходимо подтвердить корректность данных',
    duplicate_login:
      'Другой пользователь уже авторизован под данной учетной записью',
  }
}

export function parsedErrors(errors) {
  if (errors === null || errors.length === 0) return null

  return errors.map((str) => validationErrors()[str]).join(', ')
}
