/* eslint-disable */
import { useEffect, useRef, useState } from 'react'
import {toast} from "react-toastify";
import validationErrors from "../services/helpers/errors";

// https://websecret.slack.com/archives/C0528RSLL/p1567710207002600
function usePromise(f) {
  const [state, setState] = useState({ state: null })
  const isMounted = useRef(true)

  useEffect(() => () => (isMounted.current = false), [])

  return [
    {
      isPending: state.state === 'pending',
      isResolved: state.state === 'resolved',
      isRejected: state.state === 'rejected',
      result: state.result,
      exception: state.exception,
    },

    async (...args) => {
      try {
        setState({ state: 'pending' })
        const result = await f(...args)
        if (!isMounted.current) {
          return
        }
        setState({ state: 'resolved', result })
        return result
      } catch (exception) {
        console.warn(exception)
        if (!isMounted.current) {
          return
        }
        setState({ state: 'rejected', exception })
        return null
      }
    },
  ]
}

export default usePromise

/* eslint-enable */
