import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import PropTypes from 'prop-types'
import BeatLoader from 'react-spinners/BeatLoader'
import ClockLoader from 'react-spinners/ClockLoader'
import { ellipsis } from 'polished'
import { colors } from '../assets/styles/config'

// eslint-disable-next-line no-undef
const Button = React.forwardRef(
  (
    {
      onClick,
      href,
      children,
      loading,
      disabled,
      iconLeft,
      variant,
      size,
      time,
    },
    ref,
  ) => {
    const as = href ? 'a' : 'button'
    const path = href || undefined
    const loader = (
      <StyledLoader isLoading={loading}>
        {time ? (
          <ClockLoader size={18} color={colors.azure} />
        ) : (
          <BeatLoader size={10} color={colors.white} />
        )}
      </StyledLoader>
    )

    return (
      <StyledButton
        size={size}
        variant={variant}
        disabled={disabled}
        isLoading={loading}
        as={as}
        href={path}
        onClick={onClick}
        ref={ref}
      >
        {iconLeft}
        {loading && loader}
        <StyledButtonText>{children}</StyledButtonText>
      </StyledButton>
    )
  },
)

const StyledLoader = styled.span`
  font-size: 0;
  line-height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: ${(props) => (props.isLoading ? 'inline-block' : 'none')};
`

const StyledButtonSizeS = css`
  font-size: 16px;
  line-height: 19px;
  height: 32px;
  padding: 2px 12px 3px;
  font-weight: 400;
`

const StyledButtonVariantSecondaryBordered = css`
  background-color: transparent;
  color: ${(props) => props.theme.colorSecondary};
  border: 1px solid ${(props) => props.theme.grayLight};
  font-weight: 400;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }

  &:hover {
    border-color: ${(props) => props.theme.colorSecondary};
  }

  &:active {
    transform: translate3d(0, 1px, 0);
  }
`
const StyledButtonText = styled.span`
  min-width: 0;
  ${ellipsis('100%')}

  &:empty {
    display: none;
  }
`

const StyledButtonVariantGrayDarkenSquared = css`
  width: 47px;
  height: 47px;
  padding: 2px;
  background-color: transparent !important;
  color: ${(props) => props.theme.grayDarken};
  border-radius: 0;

  ${StyledButtonText} {
    display: none;
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.blueLight};
  }
`

export const StyledButton = styled.button`
  box-sizing: border-box;
  margin: 0;
  padding: 2px 24px 3px;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  border-radius: 48px;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  text-transform: none;
  background-color: ${(props) => props.theme.colorPrimary};
  background-image: none;
  outline: 0;
  display: inline-flex;
  border: 1px solid transparent;
  justify-content: center;
  position: relative;
  transition: all ${(props) => props.theme.transitionTime};
  flex-wrap: nowrap;
  max-width: 100%;
  align-items: center;
  color: ${(props) => props.theme.white};
  -webkit-appearance: none;
  height: 48px;
  opacity: ${(props) => (props.disabled && !props.isLoading ? '0.5' : '1')};
  cursor: pointer;
  pointer-events: ${(props) =>
    props.isLoading || props.disabled ? 'none' : 'auto'};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.azureLight};
  }

  &:active {
    background-color: ${(props) => props.theme.colorPrimary};
  }

  > *:not(${StyledLoader}) {
    opacity: ${(props) => (props.isLoading ? 0 : 1)};
  }

  ${up('md')} {
  }

  ${(props) =>
    props.variant === 'secondaryBordered' &&
    StyledButtonVariantSecondaryBordered}
  ${(props) => props.size === 's' && StyledButtonSizeS}

  ${(props) =>
    props.variant === 'grayDarkenSquared' &&
    StyledButtonVariantGrayDarkenSquared}
`

Button.defaultProps = {
  href: '',
  loading: false,
  disabled: false,
  size: 'm',
  variant: 'primary',
  onClick: () => {},
  iconLeft: undefined,
  time: false,
}

Button.propTypes = {
  size: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.element,
  variant: PropTypes.string,
  time: PropTypes.bool,
}

Button.displayName = 'Button'

export default Button
