import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { ellipsis } from 'polished'
import TextareaAutosize from 'react-textarea-autosize'
import { parsedErrors } from '../../services/helpers/errors'
import IconEye from '../../assets/icons/eye.svg?sprite'
import IconEyeClose from '../../assets/icons/eye-close.svg?sprite'

function FieldText({
  type,
  placeholder,
  name,
  autofocus,
  validateRules,
  defaultValue,
  disabled,
  serverErrors,
  onChange,
  IconRight,
  readonly,
  hasLabel,
  autosize,
  onKeyDown,
  themeWhite,
  hasEye,
}) {
  const [isFocus, setFocus] = useState(false)
  const [eyeState, setEyeState] = useState(false)
  const { getValues, register, errors } = useFormContext()
  const inputRef = useRef()
  const inputValue = getValues()[name] || defaultValue
  const hasValue = !!inputValue.toString().trim()
  const clientError = errors[name] || false
  const serverError = parsedErrors(serverErrors) || false
  const hasError = clientError || serverError

  const inputType = {
    number: 'tel',
    email: 'text',
    password: 'password',
    textarea: 'textarea',
  }

  const inputAs = (() => {
    if (autosize) {
      return TextareaAutosize
    }

    return inputType[type] === 'textarea' ? 'textarea' : 'input'
  })()

  const clientErrorEl = (
    <StyledFieldTextError>
      <ErrorMessage name={name} />
    </StyledFieldTextError>
  )
  const serverErrorEl = (
    <StyledFieldTextError>{serverError}</StyledFieldTextError>
  )

  useEffect(() => {
    let timeoutId
    if (autofocus) {
      timeoutId = setTimeout(() => {
        const input = inputRef.current
        const value = defaultValue

        if (
          (value === '' && !input) ||
          typeof input.selectionStart !== 'number'
        )
          return
        // eslint-disable-next-line no-multi-assign
        input.selectionStart = input.selectionEnd = value.length

        inputRef.current.focus({ preventScroll: true })
      }, 100)
    }

    return () => clearTimeout(timeoutId)
  }, [])

  function handleInput(e) {
    onChange(e.target.value)
  }

  function handleKeyDown(e) {
    onKeyDown(e)
  }

  function handleShowPasswordClick(e) {
    e.preventDefault()

    setEyeState(() => !eyeState)
  }

  const isPassword = hasEye

  const computedInputType = (() => {
    if (isPassword) {
      return eyeState ? 'text' : 'password'
    }

    return inputType[type] || 'text'
  })()

  return (
    <StyledFieldText
      disabled={disabled}
      autosize={autosize && inputType[type] === 'textarea'}
      themeWhite={themeWhite}
    >
      <StyledFieldTextInner hasIconRight={IconRight || isPassword}>
        {hasLabel && (
          <StyledFieldTextLabel
            isFocus={isFocus}
            hasValue={hasValue}
            htmlFor={name}
            type={computedInputType}
          >
            {placeholder}
          </StyledFieldTextLabel>
        )}
        <StyledFieldTextInput
          autoComplete="off"
          hasLabel={hasLabel}
          hasError={hasError}
          as={inputAs}
          readOnly={readonly || disabled}
          id={name}
          name={name}
          type={computedInputType}
          ref={(e) => {
            register(e, validateRules)
            inputRef.current = e
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          defaultValue={defaultValue}
          onChange={(e) => handleInput(e)}
          placeholder={hasLabel ? undefined : placeholder}
          onKeyDown={handleKeyDown}
        />
        {isPassword ? (
          <StyledFieldPassBtn
            onClick={handleShowPasswordClick}
            as="a"
            href="#"
            tabIndex="-1"
          >
            {eyeState ? (
              <IconEyeClose width={32} height={32} />
            ) : (
              <IconEye width={32} height={32} />
            )}
          </StyledFieldPassBtn>
        ) : (
          IconRight && <StyledIconRight>{IconRight}</StyledIconRight>
        )}
      </StyledFieldTextInner>
      {(clientError && clientErrorEl) || (serverError && serverErrorEl)}
    </StyledFieldText>
  )
}

FieldText.defaultProps = {
  autofocus: false,
  placeholder: '',
  type: 'text',
  validateRules: {},
  defaultValue: '',
  disabled: false,
  serverErrors: null,
  onChange: () => {},
  onKeyDown: () => {},
  IconRight: undefined,
  readonly: false,
  hasLabel: true,
  autosize: false,
  themeWhite: false,
  hasEye: false,
}

FieldText.propTypes = {
  autofocus: PropTypes.bool,
  hasLabel: PropTypes.bool,
  readonly: PropTypes.bool,
  hasEye: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validateRules: PropTypes.objectOf(PropTypes.any),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  serverErrors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  IconRight: PropTypes.element,
  autosize: PropTypes.bool,
  themeWhite: PropTypes.bool,
}

export const StyledIconRight = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0;
  line-height: 0;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.grayLight};
`

const StyledFieldPassBtn = styled(StyledIconRight)`
  transition: color ${(props) => props.theme.transitionTime};

  &:hover {
    color: ${(props) => props.theme.colorSecondary};
  }
`

const StyledFieldTextInputHasError = css`
  border: 1px solid ${(props) => props.theme.colorDanger};
`

const StyledFieldTextNativeLabel = css`
  padding: 12px 14px;
`

export const StyledFieldTextInput = styled('input').withConfig({
  shouldForwardProp: (prop) => {
    return !['hasError', 'hasLabel'].includes(prop)
  },
})`
  font-family: ${(props) => props.theme.fontFamily};
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  min-height: 55px;
  margin: 0;
  border: 1px solid ${(props) => props.theme.grayLighten};
  border-radius: 8px;
  padding: 24px 12px 6px;
  font-size: 18px;
  line-height: 21px;
  background-color: transparent;
  appearance: none;
  transition: all ${(props) => props.theme.transitionTime};
  color: ${(props) => props.theme.textColor};
  word-break: normal;

  &.placeholder {
    color: ${(props) => props.theme.gray};
  }

  &:-moz-placeholder {
    color: ${(props) => props.theme.gray};
  }
  &::-moz-placeholder {
    color: ${(props) => props.theme.gray};
  }
  &:-ms-input-placeholder {
    color: ${(props) => props.theme.gray};
  }
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.gray};
  }

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &:hover,
  &:focus {
    outline: 0;
    border-color: ${(props) => props.theme.grayDark};
  }

  ${(props) => props.hasError && StyledFieldTextInputHasError}
  ${(props) => !props.hasLabel && StyledFieldTextNativeLabel}
`

const StyledFieldTextDisabled = css`
  ${StyledFieldTextInput} {
    -webkit-text-fill-color: currentcolor;
    opacity: 1 !important;
    border-color: ${(props) => props.theme.grayLight};
    background-color: ${(props) => props.theme.grayLightest};
    color: ${(props) => props.theme.grayDarken};
    // pointer-events: none;
  }
`

const StyledFieldTextLabelUp = css`
  transform: scale(0.7) translateY(-12px);
  pointer-events: none;
  color: ${(props) => props.theme.textColorMuted};
  padding-right: 0 !important;
`

const StyledFieldTextAutosize = css`
  ${StyledFieldTextInput} {
    resize: none;
    min-height: 0;
    height: 47px;
    max-height: 188px;
  }
`

const StyledFieldThemeWhite = css`
  ${StyledFieldTextInput} {
    background-color: ${(props) => props.theme.white};
    box-shadow: ${(props) => props.theme.boxShadow};
  }
`

export const StyledFieldText = styled.div`
  textarea {
    resize: vertical;
    min-height: 166px;
    height: auto;
    max-height: none;
  }
  ${(props) => props.themeWhite && StyledFieldThemeWhite}
  ${(props) => props.autosize && StyledFieldTextAutosize}
  ${(props) =>
    props.disabled && StyledFieldTextDisabled}
`

const StyledFieldTextLabel = styled.label`
  ${ellipsis('100%')};
  color: ${(props) => props.theme.textColorMuted};
  font-size: 18px;
  line-height: 21px;
  position: absolute;
  top: 17px;
  left: 13px;
  transition: all ${(props) => props.theme.transitionTime};
  cursor: text;
  z-index: 1;
  transform-origin: left top;
  background-color: transparent;

  ${(props) => (props.isFocus || props.hasValue) && StyledFieldTextLabelUp}
`

const StyledFieldTextInnerHasIconRight = css`
  ${StyledFieldTextInput} {
    padding-right: 55px;
  }

  ${StyledFieldTextLabel} {
    padding-right: 55px;
  }
`

const StyledFieldTextInner = styled.div`
  position: relative;
  transform: translateZ(0);
  ${(props) => props.hasIconRight && StyledFieldTextInnerHasIconRight}
`

const StyledFieldTextError = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: ${(props) => props.theme.colorDanger};
  margin-top: 5px;
`

export default FieldText
